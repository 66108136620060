import React, { useRef } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateDoc, doc } from "firebase/firestore";
import { db, storage } from "./firebase";

const ImageUploader = ({ user, onUploadSuccess, onUploadError, children }) => {
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && user) {
      try {
        if (file.size > 5 * 1024 * 1024) {
          throw new Error("File size exceeds 5MB limit.");
        }

        const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
        if (!allowedTypes.includes(file.type)) {
          throw new Error("Invalid file type. Please upload a JPEG, PNG, or GIF image.");
        }

        const fileName = `${Date.now()}_${file.name}`;
        const storageRef = ref(storage, `profile_photos/${user.uid}/${fileName}`);

        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        await updateDoc(doc(db, "users", user.uid), {
          profilePhotoURL: downloadURL,
        });
        onUploadSuccess(downloadURL);
      } catch (error) {
        console.error("Error uploading profile photo:", error);
        onUploadError(error.message);
      }
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleImageUpload}
        accept="image/*"
        style={{ display: "none" }}
      />
      <div onClick={handleImageClick}>
        {children}
      </div>
    </>
  );
};

export default ImageUploader;
